// form
import { useFormContext, Controller, useForm } from "react-hook-form";
// @mui
import { Box, Stack,  Typography, RadioGroup, Button } from "@mui/material";
import SvgColor from "src/components/svg-color/SvgColor";

import { MaskControl, StyledCard, StyledWrap } from "../styles";
import Block from "src/components/block/Block";
import _ from "lodash";
import Iconify from "src/components/iconify";
import AddBankAccountDialog from "src/components/add-bank-account-dialog/AddBankAccountDialog";
import { useState } from "react";
import FormProvider from "src/components/hook-form/FormProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { useLocales } from "src/locales";
// ----------------------------------------------------------------------

export default function PaymentAccounts({ options, name, canAdd= false }) {
  const { control } = useFormContext();
  const [isOpen, setIsOpen] = useState(false)
  const toggleAddBankDialog = () => {
    setIsOpen(prev => !prev)
  }

  const validationSchema =   yup.object().shape({
    name: yup
      .string('Enter name')
      .min(2, 'Please enter valid name')
      .required('Name is required!'),
    account_no: yup
      .string('Enter account number')
      .min(2, 'Please enter valid account number')
      .required('Account Number is required!'),

      swift_code: yup.string().when('iban', {
        is: (val) => !val || val.trim() === '',
        then: yup.string().required('SWIFT BIC Code/Fedwire is required'),
      }),
      iban: yup.string().when('swift_code', {
        is: (val) => !val || val.trim() === '',
        then: yup.string().required('IBAN/SEPA is required'),
      }),
    branch: yup
      .string()
      .length(5, 'Must be exactly 5 digits')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Branch is required'),

    transit_number: yup
      .string()
      .length(3, 'Must be exactly 3 digits')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .required('Transit number is required'),

    lei_code: yup.string('Enter LEI code'),
    aba_number: yup.string('Enter ABA routing number'),

    ifsc_code: yup.string('Enter IFSC code'),

    bank_address: yup.string('Enter address').required('Address is required!'),
  },['iban', 'swift_code'])

  const methods = useForm({
    shouldUnregister: false,
    defaultValues:{
      name:'',
      account_no:'',
      swift_code:'',
      iban:'',
      branch:'',
      transit_number:'',
      lei_code:'',
      aba_number:'',
      ifsc_code:'',
      bank_address:''
    },
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {handleSubmit, reset} = methods;
  const onSubmit = (formState) => {
    console.log(formState,'ocwiuciuwe')
    reset();
    toggleAddBankDialog();

  }

  const {t} = useLocales();

  return (
    <Box>
      <Block title={t('selectPaymentAccount')}>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <RadioGroup name="themeMode" {...field}>
                <StyledWrap>
                  {options.map((account, idx) => (
                    <StyledCard key={idx} selected={field.value == account.id} borderType={'solid'}>
                      <SvgColor
                        src={`/assets/icons/payments/${account.type}.svg`}
                      />
                      <Stack sx={{ ml: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {account.type === "digital_asset"
                            ? account.display
                            : _.capitalize(account.display)}
                        </Typography>
                      </Stack>
                      <MaskControl value={account.id} />
                    </StyledCard>
                  ))}
                  {canAdd && options.length === 0 &&(
                    <StyledCard key={"add"} selected={false} borderType={'dashed'} onClick={toggleAddBankDialog}>
                      <Iconify icon="eva:plus-fill" width={34}/>
                      <Stack sx={{ ml: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {_.capitalize("Add New Account")}
                        </Typography>
                      </Stack>
                    </StyledCard>
                  )} 
                </StyledWrap>
              </RadioGroup>
            );
          }}
        />
      </Block>
      <FormProvider methods={methods}  >
      <AddBankAccountDialog open={isOpen} onClose={toggleAddBankDialog}  action={
              <Button
                variant="contained"
                color="success"
                type="submit"
               onClick={handleSubmit(onSubmit)}
              >
                Add
              </Button>
            }/>
            </FormProvider>
    </Box>
  );
}
