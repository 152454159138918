import { useState } from "react";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
import { PATH_AUTH } from "../../routes/paths";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
// components
import Iconify from "../../components/iconify";
import FormProvider, { RHFTextField } from "../../components/hook-form";
import OtpDialog from "src/components/otp-dialog/OtpDialog";
import { useSnackbar } from "src/components/snackbar";
import { useLocales } from "src/locales";
// ----------------------------------------------------------------------

export default function AuthLoginForm() {
  const { login, verifyOtp } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    password: Yup.string().required("Password is required"),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const {
    reset,
    setError,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const values = watch();

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
      setOpenOtpModal(true);
    } catch (error) {
      console.error(error);
      // reset();
      setError("afterSubmit", {
        ...error,
        message: error.message,
      });
    }
  };

  const onOtpSubmit = async (otp) => {
    try {
      await verifyOtp(otp, values?.email, values?.password);
    } catch (error) {
      console.log(error, "obnwncjwec");
      enqueueSnackbar("Invalid OTP", { variant: "error" });
    }
  };

  let errorMessage = errors?.afterSubmit?.response?.data?.message;

  if (errorMessage?.includes("->")) {
    errorMessage = errorMessage.split("->")[1];
  }

  const { t } = useLocales();

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {!!errors.afterSubmit && (
            <Alert severity="error">{errorMessage}</Alert>
          )}

          <RHFTextField name="email" label="Email address" />

          <RHFTextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack alignItems="flex-end" sx={{ my: 2 }}>
          <Link
            component={RouterLink}
            to={PATH_AUTH.resetPassword}
            variant="body2"
            color="inherit"
            underline="always"
          >
            {t("forgotPassword")}
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            bgcolor: "text.primary",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
            "&:hover": {
              bgcolor: "text.primary",
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          {t("login")}
        </LoadingButton>
      </FormProvider>

      <OtpDialog
        title={"Enter OTP"}
        open={openOtpModal}
        onClose={() => setOpenOtpModal(false)}
        onSubmit={onOtpSubmit}
      />
    </>
  );
}
