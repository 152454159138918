import { useState } from "react";
// @mui
import { Box, Card, Container, MenuItem, Stack, Typography } from "@mui/material";
// routes
// import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";

// import Page from "src/components/page";
import { RHFSelect, RHFTextField } from "src/components/hook-form";
import { useSelector } from "react-redux";
import { PATH_DASHBOARD } from "src/routes/paths";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import Page from "src/components/page";
import PaymentMethodsDetails from "src/sections/@dashboard/invoice/form/paymentMethods";
import { useLocales } from "src/locales";

export default function FinancialInfo() {
  const { themeStretch } = useSettingsContext();

  const {t} = useLocales()


  return (
    <>
      <Page title={t("masterAccounts")}>
        <Container maxWidth={themeStretch ? false : "lg"}>
          <CustomBreadcrumbs
            heading={t("masterAccounts")}
            links={[
              {
                name: t("dashboard"),
                href: PATH_DASHBOARD.root,
              },
              {
                name: t("masterAccounts"),
                href: PATH_DASHBOARD.receivers.list,
              },
             
            ]}
          />
          <PaymentMethodsDetails />
         
        </Container>
      </Page>
    </>
  );
}

// ----------------------------------------------------------------------
