import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, Stack, Divider, Typography, RadioGroup } from '@mui/material';
import SvgColor from 'src/components/svg-color/SvgColor';

import { MaskControl, StyledCard, StyledWrap } from '../styles';
import Block from 'src/components/block/Block';
import _ from 'lodash';
import { useLocales } from 'src/locales';
// ----------------------------------------------------------------------

export default function PaymentMethods({ options }) {
  const { control } = useFormContext();

  const uniqueArray = Array.from(new Set(options.map(obj => obj.type)))
  .map(type => options.find(obj => obj.type === type));

  const {t} = useLocales();

console.log(uniqueArray);

  return (
    <Box>
      <Block
        title={t('selectPaymentMethod')}
      >
        <Controller
          name={'payment_method'}
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <RadioGroup name="themeMode" {...field}>
                <StyledWrap>
                  {uniqueArray.map((method, idx) => (
                    <StyledCard
                      key={idx}
                      selected={field.value === method.type}
                    >
                      <SvgColor
                        src={`/assets/icons/payments/${method.type}.svg`}
                      />
                      <Stack sx={{ ml: 2 }}>
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {_.startCase(method.type.replace(/_/g, ' '))}
                        </Typography>
                      </Stack>
                      <MaskControl value={method.type} />
                    </StyledCard>
                  ))}
                </StyledWrap>
              </RadioGroup>
            );
          }}
        />
      </Block>
    </Box>
  );
}
