import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  RHFTextField,
} from 'src/components/hook-form';
import { useLocales } from 'src/locales';

function AddBankAccountDialog({ open, onClose, action }) {

  const methods = useFormContext();
  const { watch, clearErrors } = methods;

  const type = watch('type');

  const {t} = useLocales();


  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose} >
          <DialogTitle sx={{ pb: 2 }}>Add Bank Account</DialogTitle>
          <DialogContent sx={{ typography: 'body2' }}>
    <Stack gap={3} mt={3}>
      <Box
        sx={{
          display: 'grid',
          columnGap: 3,
          rowGap: 3,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          },
        }}
      >
        
            <RHFTextField name="name" label="Bank Name" />
            <RHFTextField name="account_no" label="Account No." />
            <RHFTextField name="iban" label="IBAN/SEPA" onChange={(e) => {
              clearErrors('swift_code',)
            }}/>
            <RHFTextField name="swift_code" label="SWIFT BIC Code/Fedwire" onChange={(e) => {
              clearErrors('iban')
            }} />
            <RHFTextField name="branch" label="Branch No." />
            <RHFTextField name="transit_number" label="Transit No." />
            <RHFTextField name="aba_number" label="ABA Routing No." />
            <RHFTextField name="ifsc_code" label="IFSC Code" />
            <RHFTextField name="lei_code" label="LEI Code" />

      </Box>
        <RHFTextField
          name="bank_address"
          label="Bank Address"
          multiline
          minRows={4}
          maxRows={4}
        />

    </Stack>
    </DialogContent>
    <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          {t('cancel')}
        </Button>
        {action}
      </DialogActions>
    </Dialog>
  );
}

export default AddBankAccountDialog;
