import PropTypes from 'prop-types';
import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
// @mui
import { LoadingButton } from '@mui/lab';
import { Card, Stack, Divider, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths';
// mock
// components
import FormProvider from '../../../../../components/hook-form';
//
import PaymentMethods from './PaymentMethods';
import PaymentMethodDetails from './PaymentMethodDetails';
import PaymentAccounts from './PaymentAccounts';
import { useAuthContext } from 'src/auth/useAuthContext';

// ----------------------------------------------------------------------

PaymentMethodsDetails.propTypes = {
  currentInvoice: PropTypes.object,
};


export default function PaymentMethodsDetails({canAdd=false}) {
  const {
    user: {
      mto: { finance_account },
    },
  } = useAuthContext();

  console.log(finance_account);

  const [accountOptions, setAccountOptions] = useState([]);
  const [accountDetails, setAccountDetails] = useState();

  const defaultValues = useMemo(
    () => ({
      payment_method: finance_account[0]?.type,
      account: finance_account[0]?.id.toString(),
    }),
    [finance_account]
  );

  const methods = useForm({
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  useEffect(() => {
    const values = watch();
    const accountOptions = finance_account?.filter(
      (method) => method.type === values.payment_method
    );
    setAccountOptions(accountOptions);
    setValue('account', accountOptions[0]?.id);
  }, [values.payment_method, setValue, finance_account, watch]);

  useEffect(() => {
    const values = watch();
    console.log(typeof values.account,values.account, finance_account);
    const accountDetails = finance_account?.find(
      (method) => method.id == values.account
    );
    
    console.log(accountDetails)
    setAccountDetails(accountDetails);
  }, [values.account, setValue, finance_account, watch]);

  return (
    <FormProvider methods={methods}>
      {/* {finance_account.length > 0 ? ( */}
      <Card sx={{ p: 3 }}>
        <PaymentMethods options={finance_account} />
        <Divider variant="middle" sx={{ borderStyle: 'dashed', my: 3 }} />
        <PaymentAccounts options={accountOptions} name={'account'} canAdd={canAdd} />
        <Divider variant="middle" sx={{ borderStyle: 'dashed', my: 3 }} />

        <PaymentMethodDetails info={accountDetails} />

      </Card>
    </FormProvider>
  );
}
