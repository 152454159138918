import PropTypes from 'prop-types';
// @mui
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

// ----------------------------------------------------------------------

SharePaymentDetails.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCopyLink: PropTypes.func,
};

export default function SharePaymentDetails({
  onCopyLink,

  open,
  onClose,
  ...other
}) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle> Share </DialogTitle>

      <DialogContent sx={{ overflow: 'unset' }}></DialogContent>

      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
