import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, ...other }, ref) => {
  const { mtoConfig } = useSelector((state) => state.config);

  return (
    <>
      <Helmet>
        <title>{`${title} | IP USA`}</title>
        <link
          rel="icon"
          type="image/png"
          href={'/favicon/favic.png'}
        />
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
