import PropTypes from 'prop-types';
// @mui
import { Stack, Tooltip } from '@mui/material';
//
import Iconify from '../iconify';
import TextMaxLine from '../text-max-line';

// ----------------------------------------------------------------------

const SPACING = 0;

Block.propTypes = {
  sx: PropTypes.object,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  children: PropTypes.node,
};

export default function Block({
  title,
  tooltip,
  children,
  sx,
  titleTypography,
  ...other
}) {
  return (
    <Stack spacing={1.5} sx={{ mb: SPACING, ...sx }} {...other}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          color: 'text.secondary',
        }}
      >
        {title && (
          <TextMaxLine
            variant="caption"
            sx={{ fontWeight: 'fontWeightMedium', ...titleTypography }}
          >
            {title}
          </TextMaxLine>
        )}

        {tooltip && (
          <Tooltip title={tooltip}>
            <Iconify icon="eva:info-outline" width={16} sx={{ mx: 0.5 }} />
          </Tooltip>
        )}
      </Stack>

      {children}
    </Stack>
  );
}
