import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// form
import { useFormContext } from "react-hook-form";
import useCopyToClipboard from "src/hooks/useCopyToClipboard";
import { useSnackbar } from "src/components/snackbar";
// @mui
import {
  Typography,
  Paper,
  Stack,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  Tooltip,
} from "@mui/material";
import _ from "lodash";
import { _socials } from "src/_mock/arrays";
import Block from "src/components/block/Block";
import Iconify from "src/components/iconify";
import SharePaymentDetails from "./SharePaymentDetails";
import { useAuthContext } from "src/auth/useAuthContext";

// ----------------------------------------------------------------------

//  ${infoObj.lei_code && `LEI: ${infoObj.lei_code},  `}
export default function PaymentMethodDetails({ info }) {
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuthContext();
  console.log(user);
  const { copy } = useCopyToClipboard();
  const [openShare, setOpenShare] = useState(false);
  let details;
  let url;
  let mailSubject;
  let copyContent;
  console.log(info);
  if (info) {
    switch (info.type) {
      case "bank":
        const infoObj = info.bank;
        mailSubject = `${_.capitalize(
          user?.mto?.name
        )} MTO Bank Account Details`;
        copyContent = `${_.capitalize(
          user?.mto?.name
        )} MTO Bank Account Details:
 ${infoObj.name && `Bank: ${infoObj.name},  `}
 ${infoObj.account_no && `A/C Number: ${infoObj.account_no},  `}
 ${infoObj.branch && `Branch: ${infoObj.branch},  `}
 ${infoObj.iban && `IBAN: ${infoObj.iban},  `}
 ${infoObj.ifsc_code && `IFSC: ${infoObj.ifsc_code},  `}
 ${infoObj.swift_code && `SWIFT: ${infoObj.swift_code},  `}
 ${infoObj.transit_number && `Transit Number: ${infoObj.transit_number},  `}  `;
        url = encodeURIComponent(copyContent);
        details = (
          <>
            {infoObj.name && (
              <Item key={"name"} title={"name"} content={infoObj.name} />
            )}
            {infoObj.account_no && (
              <Item
                key={"ac_no"}
                title={"ac_no"}
                content={infoObj.account_no}
              />
            )}
            {infoObj.branch && (
              <Item key={"branch"} title={"branch"} content={infoObj.branch} />
            )}
            {infoObj.iban && (
              <Item key={"iban"} title={"iban"} content={infoObj.iban} />
            )}
            {infoObj.ifsc_code && (
              <Item key={"ifsc"} title={"ifsc"} content={infoObj.ifsc_code} />
            )}
            {infoObj.lei_code && (
              <Item key={"lei"} title={"lei"} content={infoObj.lei_code} />
            )}
            {infoObj.swift_code && (
              <Item
                key={"swift"}
                title={"swift"}
                content={infoObj.swift_code}
              />
            )}
            {infoObj.transit_number && (
              <Item
                key={"transit_no"}
                title={"transit_no"}
                content={infoObj.transit_number}
              />
            )}
          </>
        );

        break;
      case "venmo":
        mailSubject = `${_.capitalize(
          user?.mto?.name
        )} MTO Venmo Account Details`;
        copyContent = `${_.capitalize(
          user?.mto?.name
        )} MTO Venmo Account Details:
${info.venmo_id && `Venmo ID: ${info.venmo_id}`}`;
        url = encodeURIComponent(copyContent);
        details = (
          <Item key={"venmo"} title={"Venmo Id"} content={info.venmo_id} />
        );

        break;
      case "paypal":
        mailSubject = `${_.capitalize(
          user?.mto?.name
        )} MTO PayPal Account Details`;
        copyContent = `${_.capitalize(
          user?.mto?.name
        )} MTO PayPal Account Details:
${info.email && `PayPal Email: ${info.email}`}`;
        url = encodeURIComponent(copyContent);
        details = (
          <Item key={"paypal"} title={"PayPal Email"} content={info.email} />
        );

        break;

      case "digital_asset":
        mailSubject = `${_.capitalize(user?.mto?.name)} MTO ${
          info.display
        } Address`;
        copyContent = `${_.capitalize(user?.mto?.name)} MTO ${
          info.display
        } Address:
  ${
    info.blockchain_address && `Blockchain Address: ${info.blockchain_address}`
  }`;
        url = encodeURIComponent(copyContent);
        details = (
          <Item
            key={"digital_asset"}
            title={`${info.display} Address`}
            content={info.blockchain_address}
            type={"digital_asset"}
          />
        );

        break;

      default:
        break;
    }
  }

  const handleCloseShare = () => {
    setOpenShare(false);
  };

  const handleCopy = (text) => {
    enqueueSnackbar("Copied!");
    copy(text);
  };

  const shareClickHandler = (type) => {
    switch (type) {
      case "copy":
        handleCopy(copyContent);
        break;
      case "whatsapp": {
        window.open(`https://wa.me/?text=${url}`, "_blank");
        break;
      }
      case "email": {
        window.open(`mailto:?subject=${mailSubject}&body=${url}`, "_blank");
        break;
      }

      default:
        break;
    }
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "space-between",
        gap: 2,
      }}
    >
      <Paper
        sx={{
          p: 1.5,
          borderRadius: 2,
          bgcolor: "background.neutral",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
          gridGap: "1rem",
          flex: 1,
        }}
      >
        {info && details}
      </Paper>
      <SpeedDial
        direction={"up"}
        ariaLabel="Share payment method info"
        icon={<Iconify icon="eva:share-fill" />}
        sx={{ "& .MuiSpeedDial-fab": { width: 48, height: 48 } }}
      >
        {_socials.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={<Iconify icon={action.icon} sx={{ color: action.color }} />}
            tooltipTitle={action.name}
            tooltipPlacement="left"
            FabProps={{ color: "default" }}
            onClick={() => shareClickHandler(action.value)}
          />
        ))}
      </SpeedDial>

      <SharePaymentDetails
        open={openShare}
        onCopyLink={handleCopy}
        onClose={() => {
          handleCloseShare();
        }}
      />
    </Stack>
  );
}

// ----------------------------------------------------------------------

function Item({ title, content, type='' }) {
  return (
    <>
      <Stack
        sx={{ cursor: "default" }}
        direction={"column"}
        spacing={0}
        alignItems={"flex-start"}
      >
        <Typography variant="overline" sx={{ color: "text.disabled" }}>
          {title}
        </Typography>
        <Stack sx={{display: "flex", flexDirection: "row", gap: 1}}>
          <Typography variant="body2">{content}</Typography>
         {type === 'digital_asset' &&
           <Tooltip title="Directly deposit funds to this address for seamless transactions." arrow placement="top">
            <Iconify
              icon={"eva:info-outline"}
              sx={{
                color: "#757575",
                margin: "0 !important",
                cursor: "pointer",
              }}
              />
          </Tooltip>
            }
        </Stack>
      </Stack>
    </>
  );
}
