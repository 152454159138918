import PropTypes from "prop-types";
import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import { API_ENDPOINTS } from "src/api";
// utils
import axios, { stbWithoutAuth } from "../utils/axios";
import localStorageAvailable from "../utils/localStorageAvailable";
//
import { isValidToken, setSession } from "./utils";
import stbServer from "../utils/axios";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGIN") {
    return {
      ...state,
      // isAuthenticated: true,
      // user: action.payload.user,
    };
  }
  if (action.type === "2FA_SUCCESS") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === "REGISTER") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === "SET_PROFILE") {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGOUT") {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable
        ? localStorage.getItem("accessToken")
        : "";

      const refreshToken = storageAvailable
        ? localStorage.getItem("refreshToken")
        : "";

      if (accessToken) {
        setSession(accessToken, refreshToken);

        const response = await stbServer.get(API_ENDPOINTS.profile);
        const user = response.data.data;

        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const response = await stbWithoutAuth.post(API_ENDPOINTS.login, {
      email,
      password,
    });
    console.log(response, "oiwbeofwiebocwebc");
    // const { token } = user;

    // setSession(token);

    dispatch({
      type: "LOGIN",
    });
  }, []);

  const verifyOtp = async (otp, email, password) => {
    const response = await stbWithoutAuth.post(API_ENDPOINTS.loginVerify, {
      otp,
      email,
      password,
    });

    const user = response.data.data;
    const { token } = user;

    setSession(token?.access, token?.refresh);

    dispatch({
      type: "2FA_SUCCESS",
      payload: {
        user,
      },
    });
  };

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await stbWithoutAuth.post("/api/account/register", {
      email,
      password,
      firstName,
      lastName,
    });
    const { token, user } = response.data;

    localStorage.setItem("accessToken", token);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  }, []);

  const getProfileData = useCallback(async () => {
    const response = await stbServer.get(API_ENDPOINTS.profile);
    const user = response.data.data;
    dispatch({
      type: "SET_PROFILE",
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null, null);
    dispatch({
      type: "LOGOUT",
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: "jwt",
      login,
      verifyOtp,
      register,
      logout,
      getProfileData,
      dispatch,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
      getProfileData,
      dispatch,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
