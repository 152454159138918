// @mui
import { enUS, zhCN } from '@mui/material/locale';
import { enUS as enUS_fns, zhCN as zhCN_fns} from 'date-fns/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    dateFnsValue: enUS_fns,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Chinese',
    value: 'cn',
    systemValue: zhCN,
    dateFnsValue: zhCN_fns,
    icon: '/assets/icons/flags/ic_flag_cn.svg',
  },
];

export const defaultLang = allLangs[0]; // English
