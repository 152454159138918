import PropTypes from 'prop-types';
//
import Image from '../../image';
import DocumentIcon from '../../../assets/icons/DocumentIcon.svg';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

SingleFilePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default function SingleFilePreview({ file }) {
  const [imgUrl, setImgUrl] = useState(file);



  const getBlobFromCloudfront = async (src) => {
    try {
      const response = await fetch(src);
      if (!response.ok) {
        throw new Error(
          `Failed to fetch the file: ${response.status} ${response.statusText}`
        );
      }

      const blob = await response.blob();
      if(blob.type === 'application/pdf') {
        setImgUrl(DocumentIcon);
      }else {
        setImgUrl(file);
      }
    } catch (error) {
      console.error("Error downloading file:", error.message);
    }
  }

  useEffect(() => {
  const type = file?.type?.split('/')[0];
    if (typeof file !== 'string') {
      if (type === 'image') {
        setImgUrl(file.preview);
      } else {
        setImgUrl(DocumentIcon);
      }
    } else {
     getBlobFromCloudfront(file);
    }
  },[file])


  if (!file) {
    return null;
  }


  return (
    <Image
      alt="file preview"
      src={imgUrl}
      sx={{
        top: 8,
        left: 8,
        zIndex: 8,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    />
  );
}
