// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  users: icon("ic_users"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  statement: icon("ic_statement"),
  deposit: icon("ic_deposit"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "dashboard",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
 

      {
        title: "programmedLiens",
        path: PATH_DASHBOARD.programmedLiens.root,
        icon: ICONS.deposit,
      },
      {
        title: "reports",
        path: PATH_DASHBOARD.reports.root,
        icon: ICONS.analytics,
        children: [
          { title: "transactionReport", path: PATH_DASHBOARD.reports.transaction },
        ],
      },
    ],
  },

  {
    subheader: "transaction",
    items: [
      {
        title: "transactions",
        path: PATH_DASHBOARD.transactions.root,
        icon: ICONS.statement,
      },
       {
        title: "beneficiaries",
        path: PATH_DASHBOARD.beneficiaries.root,
        icon: ICONS.users,
      },
      {
        title: "outgoingTransfers",
        path: PATH_DASHBOARD.outgoingTransfers.root,
        icon: ICONS.deposit,
      },
      {
        title: "claimPayment",
        path: PATH_DASHBOARD.claimPayment.root,
        icon: ICONS.invoice,
      },
      
    ],
  },

  {
    subheader: "accounts",
    items: [
      {
        title: "masterAccounts",
        path: PATH_DASHBOARD.financialInfo.root,
        icon: ICONS.banking,
      },
      {
        title: "withdrawAccounts",
        path: PATH_DASHBOARD.withdrawAccounts.root,
        icon: ICONS.banking,
      },
    ],
  },

  {
    subheader: "profile",
    items: [
      {
        title: "userAccount",
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: "businessDetails", path: PATH_DASHBOARD.user.businessDetails },
          { title: "userKybKyc", path: PATH_DASHBOARD.user.kyc },
        ],
      },
    ],
  },
  {
    subheader: "support",
    items: [
      {
        title: "chat",
        path: PATH_DASHBOARD.chat.root,
        icon: ICONS.chat,
      },
    ],
  },
];

export default navConfig;
