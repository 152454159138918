import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { useFormContext, Controller } from "react-hook-form";
import Iconify from "../iconify";

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function RHFAddressAutocomplete({
  name,
  label,
  helperText,
  onChange = () => {},
  ...other
}) {
  //   const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  const { control, setValue, getValues } = useFormContext();

  const value = getValues(name);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      console.log(results, "cwecwecwc", inputValue);
      if (active) {
        let newOptions = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          id="google-map"
          freeSolo={true}
          label={label}
          error={!!error}
          helperText={
            <FormHelperText sx={{ fontSize: 14, margin: 0, padding: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          }
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.description
          }
          options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
          noOptionsText="No locations"
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(name, typeof newValue === "string"
            ? newValue
            : newValue.description, { shouldValidate: true });
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            setValue(
              name,
              typeof newInputValue === "string"
                ? newInputValue
                : newInputValue.description,
                { shouldValidate: true }
            );
          }}
          renderInput={(params) => (
            <TextField
              multiline
              rows={4}
              label={label}
              error={!!error}
              helperText={
                <FormHelperText sx={{ fontSize: 14, margin: 0, padding: 0 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              }
              {...params}
            />
          )}
          renderOption={(props, option) => {
            const matches =
              option?.structured_formatting?.main_text_matched_substrings || [];

            const parts = parse(
              option?.structured_formatting?.main_text,
              matches?.map((match) => [
                match?.offset,
                match?.offset + match?.length,
              ])
            );

            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: "flex", width: 44 }}>
                    <Iconify icon="mdi:location"/>
                    {/* <LocationOnIcon sx={{ color: 'text.secondary' }} /> */}
                  </Grid>
                  <Grid
                    item
                    sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                  >
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                      >
                        {part?.text}
                      </Box>
                    ))}
                    <Typography variant="body2" color="text.secondary">
                      {option?.structured_formatting?.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
          {...other}
        />
      )}
    />
  );
}
