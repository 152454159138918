import { useDispatch } from 'react-redux';
import { useAuthContext } from 'src/auth/useAuthContext';
import { clearDashboardData } from 'src/redux/slices/dashboard';

const useLogoutCleanup = () => {
  const dispatch = useDispatch();
  const {logout: clearLoginData} = useAuthContext();

  const logout = () => {
    clearLoginData()
    dispatch(clearDashboardData());
  };

  return logout;
};

export default useLogoutCleanup;
